import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import DownloadButton from "../components/download"

const Page = () => (
  <Layout>
    <Seo title="Samarbeid på en delt liste i Mente" />
    <div className="longtext">
      <h2>Du er invitert til å samarbeide på en liste i Mente</h2>
      <p>
        For å godta invitasjonen trenger du Mente – appen for kontinuerlig
        forbedring gjennom lister og refleksjon! Åpne lenken på nytt etter at du
        har installert appen.
      </p>
      <div className="cta-help-box">
        <DownloadButton />
        <div style={{ marginTop: "1em" }}>
          <ul className="plainlist">
            <li>
              <Link to="/">Lær mer om Mente</Link>
            </li>
            <li>
              <Link to="/help/shared-lists">Hjelp</Link>
            </li>
          </ul>
        </div>
      </div>
      <h2>Har du appen allerede?</h2>
      <p>
        Vanligvis vil invitasjoner åpnes automatisk i Mente når du har appen
        installert, men dersom dette ikke skulle skje kan du:
        <ol>
          <li>Åpne Mente</li>
          <li>
            Gå til skjermbildet hvor du legger til en vanlig liste
            <ul>
              <li>
                <b>iOS</b>: gå til listeoversikten og trykk deretter på den
                grønne plussknappen
              </li>
              <li>
                <b>macOS</b>: Command-Shift-L
              </li>
            </ul>
          </li>
          <li>Trykke på "Importere en delt liste i stedet?"</li>
        </ol>
        <p>
          Se også <Link to="/help/shared-lists">mer hjelp for deling</Link>
        </p>
      </p>
    </div>
  </Layout>
)

export default Page
